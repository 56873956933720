import { Button, FileButton, Group, Image, Pagination, Select, Text, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
    APIDisabilityInfoExport,
    APIDisabilityInfoImport,
    APIDisabilityInfoSearch,
    APIDisabilityPrintCounterPost,
} from '../../../../api/disabilityInfo';
import { IconEdit, IconEye, IconFileExport, IconFileImport, IconPlaylistAdd, IconPrinter, IconSearch } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { PrintDisableUserInfo } from './PrintDisableUserInfo';
// @ts-ignore
import image1 from '../../../../assets/images/image1.jpg';
// @ts-ignore
import governmentLogo from '../../../../assets/images/logo.png';
import { disability_nature, disabilitySeverity, gender, showRows } from '../../../../utils/constants/Constants';
import { useForm } from '@mantine/form';
import { IMAGE_URL } from '../../../../config/baseURL';
import { useSelector } from 'react-redux';
import { errorToast, successToast } from '../../../common/toast/toast';

export const DisabilityInfo = () => {
    const userData = useSelector((state: any) => state.loggedUserReducer);
    const municipalityID = userData?.user?.municipality;
    const navigate = useNavigate();
    const [data, setData] = useState<any>();
    const [noData, setNoData] = useState(false);
    const [sn, setSn] = useState(1);
    const [page, setPage] = useState({
        current: 1,
        total: 0,
        rows: 10,
    });
    const [search, setSearch] = useState('');

    const handlePagination = (e: any) => {
        setPage({ ...page, current: e });
    };

    const handleRows = (e: any) => {
        setPage({ ...page, rows: e, current: 1 });
    };

    const fetchDisabilityData = async () => {
        let queryString = search
            ? search + `&rows=${page.rows}&current_page=${page.current}`
            : `rows=${page.rows}&current_page=${page.current}`;
        const res: any = await APIDisabilityInfoSearch(queryString);
        if (res?.status == 1) {
            setData(res?.data?.data);
            const per_page = res?.data?.per_page;
            const total = res?.data?.total;
            const pages = Math.ceil(total / per_page);
            setSn(+res?.data?.current_page);
            setPage({ ...page, total: pages, current: +res?.data?.current_page });
            setNoData(false);
        } else {
            setNoData(true);
        }
    };

    const exportUserInfoHandler = () => {
        APIDisabilityInfoExport().then((response: any) => {
            let url = window.URL.createObjectURL(response);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'userinfo.xlsx';
            a.click();
            a.remove();
        });
    };

    const fileUploadHandler = async (file: any) => {
        const data = new FormData();
        data.append('excel_file', file);
        const res: any = await APIDisabilityInfoImport(data);
        if (res?.status == 1) {
            successToast(res?.message);
            fetchDisabilityData();
        } else {
            // @ts-ignore
            errorToast('Something went wrong!') && (await fetchDisabilityData());
        }
    };

    const onPrintHandler = async (v: any, governmentLogo: any, municipalityID: any) => {
        PrintDisableUserInfo(v, governmentLogo, municipalityID);
        if (userData.user.user.role === 3) {
            const res: any = await APIDisabilityPrintCounterPost(v?.id);
            if (res?.status == 1) {
                successToast('निबेदन प्रिन्ट भएको छ।');
                fetchDisabilityData();
            } else {
                // @ts-ignore
                errorToast('Something went wrong!') && (await fetchDisabilityData());
            }
        }
    };

    const noDataRow = (
        <tr>
            <td colSpan={10} className="text-center py-sm px-sm lg:py-xs lg:text-sm">
                कुनै डाटा फेला परेन
            </td>
        </tr>
    );

    const tableRow =
        !!data &&
        data?.map((v: any, key: number) => (
            <tr key={key}>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>{(sn - 1) * page.rows + key + 1}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>{v?.full_nameNP ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>
                    <Image
                        fit={'fill'}
                        width={50}
                        height={50}
                        alt={'user_image'}
                        src={`${
                            v?.image != null
                                ? IMAGE_URL + '/storage/municipality/' + municipalityID + '/userinfo/image/' + v?.image
                                : image1
                        }`}
                    />
                </td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>{v?.identity_no ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>{v?.date_of_birthNP ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>
                    {gender.find((el) => el.value == v?.gender)?.label ?? '--'}
                </td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>
                    {disability_nature.find((el) => el.value == v?.disability_nature)?.label ?? '--'}
                </td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>
                    {disabilitySeverity.find((el) => el.value == v?.disability_severity)?.label ?? '--'}
                </td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm '}>{v?.guardians_nameNP ?? '--'}</td>
                <td className={'w-1/12 lg:py-xs lg:text-sm '}>
                    {userData?.user?.user?.role === 2 || !v.approved_by ? (
                        <Button
                            compact
                            size="sm"
                            className="editIcon p-0 mx-auto text-green-700 hover:bg-white"
                            onClick={() => navigate('/disability-info/add-new', { state: v })}
                            title={'निबेदन सच्चाउनुहोस'}
                        >
                            <IconEdit />
                        </Button>
                    ) : null}
                    {v.approved_by ? (
                        <Button
                            compact
                            size="sm"
                            className="editIcon p-0 mx-auto text-blue-700	hover:bg-white"
                            type={'submit'}
                            onClick={() => onPrintHandler(v, governmentLogo, municipalityID)}
                            title={
                                userData?.user?.user?.role === 2 ? 'निबेदन प्रिन्ट गर्नुहोस' : 'प्रतिलिपि निबेदन प्रिन्ट गर्नुहोस'
                            }
                        >
                            <IconPrinter />
                        </Button>
                    ) : null}
                    <Button
                        compact
                        size="sm"
                        className="editIcon p-0 mx-auto text-rose-700 hover:bg-white"
                        onClick={() => navigate('/disability-info/add-new-category', { state: v })}
                        title={'निबेदन तह बृद्धि'}
                    >
                        <IconPlaylistAdd />
                    </Button>
                    <Button
                        compact
                        size="sm"
                        className="editIcon p-0 mx-auto text-rose-700 hover:bg-white"
                        onClick={() => navigate('view', { state: {data:v, municipalityID} })}
                        title={'हेर्नुहोस्'}
                    >
                        <IconEye />
                    </Button>
                </td>
            </tr>
        ));

    useEffect(() => {
        fetchDisabilityData();
    }, [page.current, page.rows, search]);

    const searchForm = useForm({
        initialValues: {
            name: '',
            gen: '',
            dn: '',
            ds: '',
            ward: '',
        },
    });

    const seachSubmitHandler = async (data: object) => {
        let queryString = '';
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null && value !== undefined && value.trim() !== '') {
                queryString += `${key}=${value.trim()}&`;
            }
        });
        queryString = queryString.slice(0, -1);
        setSearch(queryString);
        setPage({ ...page, current: 1 });
    };

    return (
        <>
            <Text align={'center'} className={'text-xl font-semibold'}>
                अपाङ्गता परिचयपत्र व्यवस्थापन
            </Text>
            <div className={'grid gap-[10px] md:flex justify-between mt-md'}>
                <div>
                    <Button
                        className={'bg-primary-700 text-md lg:w-1.5/12 lg:text-sm lg:px-xs'}
                        onClick={() => navigate('/disability-info/add-new')}
                    >
                        नया बनाउनुहोस
                    </Button>
                </div>
                <Group position={'right'} className={'gap-[10px]'}>
                    <FileButton onChange={(file) => fileUploadHandler(file)} accept=".csv,.xlsx">
                        {(props) => (
                            <Button className={'bg-primary-700 '} {...props} title={'Import'}>
                                <IconFileImport size={18} className={'mr-xs'} /> <span className={' lg:visible'}>Import</span>
                            </Button>
                        )}
                    </FileButton>
                    <Button title={'Export'} className={'bg-primary-700 '} onClick={() => exportUserInfoHandler()}>
                        <IconFileExport size={18} className={'mr-xs'} /> Export
                    </Button>
                </Group>
            </div>

            {/*search */}
            <form
                className={'grid  gap-[10px] lg:grid-cols-6 md:grid-cols-1 mt-md'}
                onSubmit={searchForm.onSubmit(() => seachSubmitHandler(searchForm.values))}
            >
                <TextInput
                    // size={'xs'}
                    type={'search'}
                    placeholder={'नाम'}
                    {...searchForm.getInputProps('name')}
                />
                <Select
                    clearable
                    // size={'xs'}
                    placeholder={'लिङ्ग छान्नुहोस्'}
                    {...searchForm.getInputProps('gen')}
                    data={gender}
                />
                <TextInput
                    // size={'xs'}
                    type={'search'}
                    placeholder={'वार्ड नं.'}
                    {...searchForm.getInputProps('ward')}
                />
                <Select
                    clearable
                    // size={'xs'}
                    placeholder={'अपाङ्ग प्रकृति छान्नुहोस्'}
                    {...searchForm.getInputProps('dn')}
                    data={disability_nature}
                />
                <Select
                    clearable
                    // size={'xs'}
                    placeholder={'अपाङ्गताको गम्भीरता छान्नुहोस्'}
                    {...searchForm.getInputProps('ds')}
                    data={disabilitySeverity}
                />
                <Button className={'bg-primary-700'} type={'submit'}>
                    <IconSearch size={20} className={'mr-xs'} />
                    <span>खोज्नुहोस</span>
                </Button>
            </form>

            {/*table */}
            {!noData && page.total > 1 ? (
                <div className={' mt-md flex justify-end gap-[10px]'}>
                    <Select
                        size={'xs'}
                        className={'lg:w-1/12 sm:w-2/12'}
                        placeholder="No. of Rows"
                        onChange={handleRows}
                        data={showRows}
                    />
                    <Pagination
                        position="right"
                        className=""
                        page={page.current}
                        total={page.total}
                        onChange={handlePagination}
                    />
                </div>
            ) : null}

            <div className={'overflow-scroll mt-md'}>
                <table className={'table-fixed w-full text-md text-left text-black-500 '}>
                    <thead className={'text-md text-gray-700 uppercase w-full bg-gray-50 lg:text-sm lg:py-[0px]  '}>
                        <tr>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs   w-[80px] '}>
                                क्र.स
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                नाम
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                फोटो
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                प.प.न.
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                जन्ममिति
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs w-[80px] '}>
                                लिङ्ग
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                अपांगता प्रकृति
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                गम्भीरता
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs  '}>
                                संरक्षकको नाम
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs w-1/10 '}>
                                कैफियत
                            </th>
                        </tr>
                    </thead>
                    <tbody className={'w-full'}>{noData ? noDataRow : tableRow}</tbody>
                </table>
            </div>
        </>
    );
};
