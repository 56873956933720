import { Button, FileButton, Group, Image, Pagination, Select, Text, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
    // APISeniorCitizenInfo,
    APISeniorCitizenInfoExport,
    APISeniorCitizenInfoImport,
    APISeniorCitizenInfoSearch,
} from '../../../../api/seniorcitizeninfo';
import { IconEdit, IconFileExport, IconFileImport, IconPrinter, IconSearch } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { PrintSeniorCitizenInfo } from './PrintSeniorCitizenInfo';
//@ts-ignore
import image1 from '../../../../assets/images/image1.jpg';
// @ts-ignore
import governmentLogo from '../../../../assets/images/logo.png';
import { gender, showRows } from '../../../../utils/constants/Constants';
import { useForm } from '@mantine/form';
import { IMAGE_URL } from '../../../../config/baseURL';
import { useSelector } from 'react-redux';
import { errorToast, successToast } from '../../../common/toast/toast';

export const SeniorCitizenInfo = () => {
    const userData = useSelector((state: any) => state.loggedUserReducer);
    const municipalityID = userData?.user?.municipality;

    const navigate = useNavigate();
    const [data, setData] = useState<any>();
    const [noData, setNoData] = useState(false);
    const [sn, setSn] = useState(1);
    const [page, setPage] = useState({
        current: 1,
        total: 0,
        rows: 10,
    });
    const [search, setSearch] = useState('');

    const handlePagination = (e: any) => {
        setPage({ ...page, current: e });
    };

    const handleRows = (e: any) => {
        setPage({ ...page, rows: e, current: 1 });
    };

    const fetchSeniorCitizenData = async () => {
        let queryString = search
            ? search + `&rows=${page.rows}&current_page=${page.current}`
            : `rows=${page.rows}&current_page=${page.current}`;
        const res: any = await APISeniorCitizenInfoSearch(queryString);
        if (res?.status == 1) {
            setData(res?.data?.data);
            const per_page = res?.data?.per_page;
            const total = res?.data?.total;
            const pages = Math.ceil(total / per_page);
            setSn(+res?.data?.current_page);
            setPage({ ...page, total: pages, current: +res?.data?.current_page });
            setNoData(false);
        } else {
            setNoData(true);
        }
    };

    const exportUserInfoHandler = () => {
        APISeniorCitizenInfoExport().then((response: any) => {
            let url = window.URL.createObjectURL(response);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'seniorcitizeninfo.xlsx';
            a.click();
            a.remove();
        });
    };

    const fileUploadHandler = async (file: any) => {
        const data = new FormData();
        data.append('excel_file', file);
        const res: any = await APISeniorCitizenInfoImport(data);
        if (res?.status == 1) {
            successToast(res?.message);
            fetchSeniorCitizenData();
        } else {
            // @ts-ignore
            errorToast('Something went wrong!') && (await fetchSeniorCitizenData());
        }
    };

    const noDataRow = (
        <tr>
            <td colSpan={10} className="text-center py-sm px-sm lg:py-xs lg:text-sm">
                कुनै डाटा फेला परेन
            </td>
        </tr>
    );

    const tableRow =
        !!data &&
        data?.map((v: any, key: number) => (
            <tr key={key}>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{(sn - 1) * page.rows + key + 1}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{v?.full_name ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>
                    <Image
                        fit={'fill'}
                        width={50}
                        height={50}
                        alt={'user_image'}
                        src={`${
                            v?.image != null
                                ? IMAGE_URL + '/storage/municipality/' + municipalityID + '/senior-citizen/image/' + v?.image
                                : image1
                        }`}
                    />
                </td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{v?.identity_no ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{v?.date_of_birth ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{gender.find((el) => el.value == v?.gender)?.label ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{v?.guardians_name ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{v?.date_of_issue ?? '--'}</td>
                <td className={'py-sm px-sm lg:py-xs lg:text-sm'}>{v?.issued_district ?? '--'}</td>
                <td className={'w-1/12 lg:py-xs lg:text-sm'}>
                    {userData?.user?.user?.role === 2 ? (
                        <Button
                            compact
                            size="sm"
                            className="editIcon p-0 mx-auto text-green-700 hover:bg-white"
                            onClick={() => navigate('/senior-citizen/add-new', { state: v })}
                            title={'निबेदन सच्चाउनुहोस'}
                        >
                            <IconEdit />
                        </Button>
                    ) : null}
                    <Button
                        compact
                        size="sm"
                        className="editIcon p-0 mx-auto text-blue-700	hover:bg-white"
                        type={'submit'}
                        onClick={() => PrintSeniorCitizenInfo(v, governmentLogo, municipalityID)}
                        title={userData?.user?.user?.role === 2 ? 'निबेदन प्रिन्ट गर्नुहोस' : 'प्रतिलिपि निबेदन प्रिन्ट गर्नुहोस'}
                    >
                        <IconPrinter />
                    </Button>
                </td>
            </tr>
        ));

    useEffect(() => {
        fetchSeniorCitizenData();
    }, [page.current, page.rows, search]);

    const searchForm = useForm({
        initialValues: {
            name: '',
            gen: '',
            ward: '',
            cn: '',
        },
    });

    const seachSubmitHandler = async (data: object) => {
        let queryString = '';
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null && value !== undefined && value.trim() !== '') {
                queryString += `${key}=${value.trim()}&`;
            }
        });
        queryString = queryString.slice(0, -1);
        setSearch(queryString);
        setPage({ ...page, current: 1 });
        // const res: any = await APISeniorCitizenInfoSearch(queryString);
        // setData(res?.data?.data);
    };

    return (
        <>
            <Text align={'center'} className={'text-xl font-semibold'}>
                जेष्ठ नागरिक परिचयपत्र व्यवस्थापन
            </Text>
            <div className={'grid gap-[10px] md:flex justify-between mt-md'}>
                <div>
                    <Button
                        className={'bg-primary-700 text-md lg:w-1.5/12 lg:text-sm lg:px-xs'}
                        onClick={() => navigate('/senior-citizen/add-new')}
                    >
                        नया बनाउनुहोस
                    </Button>
                </div>
                <Group position={'right'} className={'gap-[10px]'}>
                    <FileButton onChange={(file) => fileUploadHandler(file)} accept=".csv,.xlsx">
                        {(props) => (
                            <Button className={'bg-primary-700 '} {...props} title={'Import'}>
                                <IconFileImport size={18} className={'mr-xs'} /> <span className={' lg:visible'}>Import</span>
                            </Button>
                        )}
                    </FileButton>
                    <Button title={'Export'} className={'bg-primary-700 '} onClick={() => exportUserInfoHandler()}>
                        <IconFileExport size={18} className={'mr-xs'} /> Export
                    </Button>
                </Group>
            </div>

            {/*search */}
            <form
                className={'grid  gap-[10px] lg:grid-cols-5 md:grid-cols-1 mt-md items-center'}
                onSubmit={searchForm.onSubmit(() => seachSubmitHandler(searchForm.values))}
            >
                <TextInput type={'search'} placeholder={'नाम'} {...searchForm.getInputProps('name')} />
                <TextInput type={'search'} placeholder={'नागरिकता नं.'} {...searchForm.getInputProps('cn')} />
                <Select clearable placeholder={'लिङ्ग छान्नुहोस्'} {...searchForm.getInputProps('gen')} data={gender} />
                <TextInput type={'search'} placeholder={'वार्ड नं.'} {...searchForm.getInputProps('ward')} />
                <Button className={'bg-primary-700'} type={'submit'}>
                    <IconSearch size={20} className={'mr-xs'} />
                    <span>खोज्नुहोस</span>
                </Button>
            </form>

            {/*table */}
            {!noData && page.total > 1 ? (
                <div className={' mt-md flex justify-end gap-[10px]'}>
                    <Select
                        size={'xs'}
                        className={'lg:w-1/12 sm:w-2/12'}
                        placeholder="No. of Rows"
                        onChange={handleRows}
                        data={showRows}
                    />
                    <Pagination
                        position="right"
                        className=""
                        page={page.current}
                        total={page.total}
                        onChange={handlePagination}
                    />
                </div>
            ) : null}

            <div className={'overflow-scroll mt-lg'}>
                <table className={'table-fixed w-full text-md text-left text-black-500 '}>
                    <thead className={'text-md text-gray-700 uppercase w-full bg-gray-50 lg:text-sm lg:py-[0px]  '}>
                        <tr>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs w-[80px]'}>
                                क्र.स
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                नाम
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                फोटो
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                प.प.न.
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                जन्ममिति
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs w-[80px]'}>
                                लिङ्ग
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                संरक्षकको नाम
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                जारी मिति
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs'}>
                                जारी जिल्ला
                            </th>
                            <th scope={'col'} className={'py-sm px-sm lg:py-xs w-1/12'}>
                                कैफियत
                            </th>
                        </tr>
                    </thead>
                    <tbody className={'w-full'}>{noData ? noDataRow : tableRow}</tbody>
                </table>
            </div>
        </>
    );
};
