import { APIAuthenticatedUser } from '../../api';
import { clearStorage, saveRole, saveToken, saveUser } from '../../utils/helpers/localStorage.helpers';
import * as actionTypes from './auth.actionTypes';
import { LOGOUT_USER } from './auth.actionTypes';
import axiosInstance from '../../services/axiosInterceptor';

export const setAuthData = (data: any) => {
    return {
        type: actionTypes.SET_AUTH_DATA,
        payload: data,
    };
};

export const authenticatedUser = (user: any) => async (dispatch: any) => {
    const res: any = await APIAuthenticatedUser(user);
    if ([201, 200, 1].includes(res?.status)) {
        dispatch(setAuthData(res?.data?.logged_user?.name));
        saveToken(res?.data?.token);
        saveRole(res?.data?.logged_user?.role);
        saveUser(res?.data?.logged_user?.name);
    }
    return res;
};

const deleteAuthorizationHeader = () => {
    delete axiosInstance.defaults.headers.common.Authorization;
};

export const logout = () => async (dispatch: any) => {
    clearStorage();
    deleteAuthorizationHeader();
    dispatch({ type: LOGOUT_USER });
};
