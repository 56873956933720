import { Button, Card, FileButton, FileInput, Grid, Group, Modal, Select, Text, TextInput } from '@mantine/core';
import { matches, useForm } from '@mantine/form';
import { errorToast, successToast } from '../../../common/toast/toast';
import { APIDisabilityInfoPost, APIDisabilityInfoPut } from '../../../../api/disabilityInfo';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { IconCamera, IconCloudUpload, IconX } from '@tabler/icons';
import { PreviewImage } from './PreviewImage';
import { Camera } from 'react-camera-pro';
// @ts-ignore
import image1 from '../../../../assets/images/image1.jpg';
// @ts-ignore
import governmentLogo from '../../../../assets/images/logo.png';
import 'nepali-datepicker-reactjs/dist/index.css';
import {
    bloodGroupData,
    disability_nature,
    disabilitySeverity,
    gender,
    guardiansRelationNP,
    idCardTypes,
    maritalStatusData,
} from '../../../../utils/constants/Constants';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { useLocation } from 'react-router';
import { EmployeePositionDTO, UserInfoDTO } from '../../../../utils/helpers/UserInfoFormater';
import { APIEmployeePosition } from '../../../../api/employee';
import { useSelector } from 'react-redux';
import { PrintDisableUserInfo } from './PrintDisableUserInfo';
import { IMAGE_URL } from '../../../../config/baseURL';
import { nepaliToEnglish } from '../../../../utils/helpers/dateConverter';

export const DisableUserInfoForm = () => {
    const { state } = useLocation();
    const userData = useSelector((state: any) => state.loggedUserReducer);
    const municipalityID = userData?.user?.municipality;
    const [employeePost, setEmployeePost] = useState<any[]>([]);
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);

    const card_types = JSON.parse(sessionStorage.getItem('card-type') ?? '');
    const card_id = card_types?.find((v: any) => v.card_nameEN === 'disability identity card')?.id;

    const form = useForm({
        initialValues: {
            identity_no: '',
            full_nameEN: '',
            addressEN: '',
            districtEN: 'Udayapur',
            provinceEN: 'Koshi Province',
            contact_no: '',
            citizenship_no: '',
            date_of_birthEN: '',
            gender: '',
            blood_group: '',
            guardians_nameEN: '',
            guardians_relation: '',
            disability_nature: '',
            disability_severity: '',
            card_class: '',
            marital_status: '',
            signature: '',
            full_nameNP: '',
            addressNP: '',
            districtNP: 'उदयपुर',
            provinceNP: 'कोशी प्रदेश',
            date_of_birthNP: '',
            guardians_nameNP: '',
            card_type_id: card_id,
            hospital_certificate: '',
            ward_no: '',
            approved_by: '',
        },

        validate: {
            full_nameNP: matches(/[\u0900-\u097F]/, 'पुरा नाम नेपलीमा लेख्नुहोस्!'),
            guardians_nameNP: matches(/[\u0900-\u097F]+$/, 'नेपलीमा लेख्नुहोस्'),
            identity_no: (value) => (value ? null : 'कृपया परिचयपत्र नं लेख्नुहोस् '),
            full_nameEN: (value) => (value ? null : 'Please enter full name in english'),
            // addressEN: '',
            // date_of_birthEN: '',
            gender: (value) => (value ? null : 'कृपया लिङ्ग छान्नुहोस्'),
            guardians_nameEN: (value) => (value ? null : 'Please enter full name in english'),
            guardians_relation: (value) => (value ? null : 'कृपया संरक्षकसंगको नाता छान्नुहोस्'),
            disability_nature: (value) => (value ? null : 'कृपया अपाङ्गताको प्रकृति छान्नुहोस्'),
            disability_severity: (value) => (value ? null : 'कृपया अपाङ्गताको गम्भीरता छान्नुहोस्'),
            // card_class: '',
            marital_status: (value) => (value ? null : 'कृपया बैबाहिक छान्नुहोस्'),
            // addressNP: (value) => (value ? null : 'कृपया ठेगाना लेख्नुहोस्'),
            // date_of_birthNP: '',
            ward_no: (value) => (value ? null : 'कृपया वार्ड नं लेख्नुहोस्'),
            // guardians_nameNP:(value)=>value?null:'कृपया संरक्षकको नाम लेख्नुहोस्',
        },
    });
    // const [date, setDate] = useState<string>("")

    const employeePosition = async () => {
        const res: any = await APIEmployeePosition();
        const data = res.data.data.map((val: any) => EmployeePositionDTO.receive(val));

        setEmployeePost(data.map((v: any) => UserInfoDTO.receive(v)));
    };

    useEffect(() => {
        employeePosition();
        if (state !== null || undefined) {
            // form.setValues(state)
            form.setValues(UserInfoDTO.receive(state));
        }
    }, [state]);
    const [selectedImage, setSelectedImage] = useState<Blob>();
    console.log("🚀 ~ file: DisableUserInfoForm.tsx:112 ~ DisableUserInfoForm ~ selectedImage:", typeof(selectedImage))
    const [selectedHospitalDoc, setSelectedHospitalDoc] = useState<Blob>();

    //conversion of image
    function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new File(byteArrays, 'image', { type: contentType });
    }

    const submitHandler = async (data: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value as any);
        }
        if (!!selectedImage && typeof selectedImage == 'string') {
            // @ts-ignore
            let image1 = selectedImage?.split(',');
            var image2 = image1[1];

            const file = b64toBlob(image2, 'image/jpeg', 512);
            formData.append('image', file);
        } else {
            formData.append('image', selectedImage as any);
        }
        {
            !!selectedHospitalDoc ? formData.append('hospital_certificate', selectedHospitalDoc as any) : null;
        }

        try {
            if (!!state?.id) {
                const res: any = await APIDisabilityInfoPut(formData, Number(state?.id));
                res?.status == 1
                    ? (navigate('/disability-info'),
                      successToast('तपाईको निबेदन पेस भएको छ|'),
                      res.data.approved_by ? PrintDisableUserInfo(res?.data, governmentLogo, municipalityID) : null)
                    : errorToast('Something went wrong, please try again!');
            } else {
                const res: any = await APIDisabilityInfoPost(formData);
                res?.status == 1
                    ? (navigate('/disability-info'),
                      successToast('तपाईको निबेदन पेस भएको छ|'),
                      res.data.approved_by ? PrintDisableUserInfo(res?.data, governmentLogo, municipalityID) : null)
                    : errorToast('Something went wrong, please try again!');
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };
    const [englishDate,setEnglishDate]=useState<any>({year:null,
month:'',day:""})
// console.log(form.values.date_of_birthNP);
useEffect(()=>{
    if(!!form.values.date_of_birthNP){

        const englishdateArray:any[] = form?.values?.date_of_birthNP?.split("-");
        setEnglishDate(nepaliToEnglish(Number(englishdateArray[0]),Number(englishdateArray[1]),Number(englishdateArray[2])));
        form.setFieldValue('date_of_birthEN',`${englishDate.year}-${englishDate.month}-${englishDate.day}`)
    }

},[form.values.date_of_birthNP])

// console.log(typeof(englishDate.year));


    const camera = useRef<any>(null);
    // @ts-ignore
    return (
        <Grid className={'m-[0px] h-full w-full overflow-y-scroll overflow-x-hidden '}>
            <Modal opened={opened} onClose={() => setOpened(false)} centered size={350} className={'w-full'}>
                <div className={'h-[400px] w-[400px] flex flex-col mx-auto items-center justify-center'}>
                    <Camera
                        aspectRatio={4 / 3}
                        ref={camera}
                        facingMode={'environment'}
                        errorMessages={{
                            noCameraAccessible: 'camera is not available',
                            permissionDenied: 'permissionDenied',
                            switchCamera: 'front',
                            canvas: 'canvas',
                        }}
                    />

                    <Group className={'flex justify-around w-[250px] mb-md'}>
                        <Button
                            size={'xs'}
                            className={'bg-primary-700 mt-sm '}
                            onClick={() => {
                                setSelectedImage(camera.current.takePhoto()), setOpened(false);
                            }}
                        >
                            Take photo
                        </Button>
                        <Button size={'xs'} className={'bg-primary-700 mt-sm '} onClick={() => setOpened(false)}>
                            Cancel
                        </Button>
                    </Group>
                </div>
            </Modal>
            <Grid.Col className={''} offset={2} span={11}>
                <Card>
                    <Grid.Col offset={4}>
                        <Text className={'font-black text-xl underline'}> अपाङ्ग नागरिक दर्ता </Text>
                    </Grid.Col>
                    <form className={'w-full flex '} onSubmit={form.onSubmit((values) => submitHandler(values))}>
                        <Grid.Col span={4}>
                            <TextInput
                                className={'py-xs mt-md'}
                                label="प.प.नं"
                                withAsterisk
                                mask="00-00"
                                placeholder="परिचय पत्र नं"
                                {...form.getInputProps('identity_no')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="नागरिकता नं."
                                placeholder=""
                                {...form.getInputProps('citizenship_no')}
                            />
                            <TextInput
                                withAsterisk
                                className={'py-xs'}
                                label="पुरा नाम"
                                placeholder="पुरा नाम लेख्नुहोस्"
                                {...form.getInputProps('full_nameNP')}
                            />

                            <Select
                                withAsterisk
                                className={'py-xs'}
                                label="लिङ्ग"
                                placeholder="छान्नुहोस्"
                                data={gender}
                                {...form.getInputProps('gender')}
                            />
                            {/*<TextInput*/}
                            {/*  withAsterisk*/}
                            {/*  className={'py-xs'}*/}
                            {/*  label='जन्म मिति (बि.सं)'*/}
                            {/*  placeholder='२०२२-१०-१५'*/}
                            {/*  {...form.getInputProps('date_of_birthNP')}*/}
                            {/*/>*/}

                            <div className="mantine-InputWrapper-root mantine-TextInput-root py-xs mantine-14eweac">
                                <label
                                    className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"
                                    htmlFor="mantine-r1"
                                    id="mantine-r1-label"
                                >
                                    जन्म मिति (बि.सं)
                                    <span
                                        className="mantine-u5apz8 mantine-InputWrapper-required mantine-TextInput-required"
                                        aria-hidden="true"
                                    >
                                        *
                                    </span>
                                </label>
                                <div className="mantine-Input-wrapper mantine-TextInput-wrapper mantine-12sbrde">
                                    <NepaliDatePicker
                                        inputClassName={`mantine-Input-input mantine-TextInput-input mantine-1j4mrfo`}
                                        {...form.getInputProps('date_of_birthNP')}
                                        options={{ calenderLocale: 'ne', valueLocale: 'en' }}
                                    />
                                </div>
                            </div>

                            <TextInput
                                className={'py-xs'}
                                label="प्रदेश"
                                placeholder=""
                                disabled
                                {...form.getInputProps('provinceNP')}
                            />

                            <TextInput
                                className={'py-xs'}
                                label="ठेगाना"
                                placeholder="ठेगाना"
                                {...form.getInputProps('addressNP')}
                            />
                            <TextInput
                                withAsterisk
                                className={'py-xs'}
                                min={1}
                                label="वार्ड नं."
                                placeholder="वार्ड नं."
                                {...form.getInputProps('ward_no')}
                            />
                            <TextInput
                                withAsterisk
                                className={'py-xs'}
                                label="संरक्षकको नाम"
                                placeholder="संरक्षकको पुरा नाम लेख्नुहोस् "
                                {...form.getInputProps('guardians_nameNP')}
                            />

                            <Select
                                withAsterisk
                                className={'py-xs'}
                                label="संरक्षकसंगको नाता "
                                data={guardiansRelationNP}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('guardians_relation')}
                            />

                            <Select
                                withAsterisk
                                className={'py-xs'}
                                label="परिचय पत्रको प्रकार"
                                data={idCardTypes}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('card_class')}
                            />

                            <Select
                                withAsterisk
                                className={'py-xs'}
                                label="अपाङ्गताको प्रकृति"
                                data={disability_nature}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('disability_nature')}
                            />
                            <Select
                                withAsterisk
                                className={'py-xs'}
                                data={disabilitySeverity}
                                label="अपाङ्गताको गम्भीरता"
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('disability_severity')}
                            />
                        </Grid.Col>
                        <Grid.Col offset={0.5} span={4}>
                            <div className="flex mb-sxs">
                                <div className={''}>
                                    <PreviewImage
                                        src={
                                            !!selectedImage
                                                ? typeof(selectedImage)== 'object'?URL.createObjectURL(selectedImage):selectedImage
                                                : state?.image
                                                ? `${
                                                      IMAGE_URL +
                                                      '/storage/municipality/' +
                                                      municipalityID +
                                                      '/userinfo/image/' +
                                                      state?.image
                                                  }`
                                                : image1
                                        }
                                    />
                                </div>
                                <Group className={'flex flex-col '}>
                                    {!!selectedImage ? (
                                        <Button
                                            onClick={() => setSelectedImage(undefined)}
                                            className={
                                                'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                            }
                                        >
                                            <IconX className={'mr-xs'} /> Remove image
                                        </Button>
                                    ) : (
                                        <FileButton onChange={(e: any) => setSelectedImage(e)} accept="image/png,image/jpeg">
                                            {(props) => (
                                                <Button
                                                    className={
                                                        'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                                    }
                                                    {...props}
                                                >
                                                    <IconCloudUpload className={'mr-xs'} /> Upload image
                                                </Button>
                                            )}
                                        </FileButton>
                                    )}
                                    <Button
                                        className={
                                            'text-black font-normal bg-white mt-sm hover:bg-white border-none drop-shadow-md'
                                        }
                                        onClick={() => setOpened(true)}
                                    >
                                        <IconCamera className={'mr-xs font-normal'} />
                                        {selectedImage ? 'Retake Picture' : 'Take Picture'}
                                    </Button>
                                </Group>
                            </div>
                            <TextInput
                                withAsterisk
                                className={'py-xs'}
                                label="Full Name"
                                placeholder=""
                                {...form.getInputProps('full_nameEN')}
                            />
                            <Select
                                className={'py-xs'}
                                label={'रक्त समूह'}
                                data={bloodGroupData}
                                placeholder={'छान्नुहोस्'}
                                {...form.getInputProps('blood_group')}
                            />
                            <TextInput
                                withAsterisk
                                className={'py-xs'}
                                label="Date of Birth (A.D)"
                                value={typeof(englishDate.year)== 'string'?  `${englishDate.year}-${englishDate.month}-${englishDate.day}`:''}
                                placeholder=""
                            />

                            <TextInput
                                className={'py-xs'}
                                label="जिल्ला"
                                disabled
                                placeholder=""
                                {...form.getInputProps('districtNP')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="Address"
                                placeholder="Address"
                                {...form.getInputProps('addressEN')}
                            />
                            <TextInput
                                className={'py-xs'}
                                label="सम्पर्क नं."
                                placeholder="९८७६५४३२१०"
                                {...form.getInputProps('contact_no')}
                            />

                            <TextInput
                                className={'py-xs'}
                                label="Guardians Name"
                                withAsterisk
                                placeholder=""
                                {...form.getInputProps('guardians_nameEN')}
                            />

                            <Select
                                withAsterisk
                                className={'py-xs'}
                                label="बैबाहिक स्थिति"
                                data={maritalStatusData}
                                placeholder="छान्नुहोस्"
                                {...form.getInputProps('marital_status')}
                            />

                            <Select
                                className={'py-xs'}
                                label="प्रमाणित गर्ने "
                                nothingFound="No result found"
                                placeholder="छान्नुहोस्"
                                data={employeePost}
                                {...form.getInputProps('approved_by')}
                            />
                            <FileInput
                                className={'py-xs'}
                                placeholder={'Upload hospital Certificate'}
                                label={'Hospital Certificate'}
                                onChange={(e: any) => setSelectedHospitalDoc(e)}
                            />

                            <Group position="right" mt="lg">
                                <Button className={'bg-primary-700'} type="submit">
                                    Submit
                                </Button>
                            </Group>
                        </Grid.Col>
                    </form>
                </Card>
            </Grid.Col>
        </Grid>
    );
};
