import { Button, Card, Grid, PasswordInput, TextInput, Text, Group, Image } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Loading from '../../../common/Loading';
import { successToast, errorToast } from '../../../common/toast/toast';
import { LoginUserDTO } from '../types/login.type';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { rootReducer } from '../../../../store/rootReducer';
import { authenticatedUser } from '../../../../store/auth/auth.action';
// @ts-ignore
import GovermentLogo from '../../../../assets/images/logo.png';

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    type ReduxState = ReturnType<typeof rootReducer>;

    type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;

    const dispatch = useDispatch<TypedDispatch>();

    if (isLoading) return <Loading />;

    const loginUser = async () => {
        setIsLoading(true);
        try {
            const res: any = await dispatch(authenticatedUser(LoginUserDTO(form.values)));
            if (res?.data?.token) {
                navigate('/');
                successToast('Successfully login');
            } else {
                errorToast('Something went wrong, please try again!');
            }
            setIsLoading(false);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    return (
        <div className={'h-[100vh] w-full '}>
            <Grid className="m-[0px] flex h-full justify-center items-center drop-shadow-md ">
                <Grid.Col md={3}>
                    <Card className={''}>
                        <Group className={'flex flex-col'}>
                            <Image src={GovermentLogo} height={100} width={100} />
                            <Text className={'text-xl font-semibold '}>नमुना नगरपालिका</Text>
                            <Text className={'text-xl font-bold '}>नगर कार्यपालिकाको कार्यालय</Text>
                            <Text className={'text-xl font-semibold '}>कार्ड ब्यवस्थापन प्रणाली</Text>
                        </Group>
                        <form onSubmit={form.onSubmit(() => loginUser())}>
                            <TextInput
                                required
                                label="Email"
                                placeholder="your@email.com"
                                py={'md'}
                                className={'text-xl'}
                                value={form.values.email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    form.setFieldValue('email', event.target.value)
                                }
                            />
                            <PasswordInput
                                label="Password"
                                placeholder="Password"
                                value={form.values.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    form.setFieldValue('password', event.target.value)
                                }
                                autoComplete="on"
                            />
                            <Group position="right" mt={'xs'}>
                                <Link to="/auth/send-forgot-password" className="text-rose-800 text-md">
                                    Forgot password?
                                </Link>
                            </Group>
                            <div className="flex justify-center mb-md">
                                <Button type="submit" className="bg-primary-700 px-md mt-xs">
                                    Sign In
                                </Button>
                            </div>
                        </form>
                    </Card>
                </Grid.Col>
            </Grid>
        </div>
    );
};

export default Login;
