//@ts-nocheck
import { Navbar, Group, ScrollArea, Button, Box, ThemeIcon, UnstyledButton } from '@mantine/core';
import { IconGauge, IconNote, IconSettings } from '@tabler/icons';
import { LinksGroup, useStyles } from './LinksGroup';
import { Logo } from '../logo/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { successToast } from '../toast/toast';
import { Link } from 'react-router-dom';
import { logout } from '../../../store/auth/auth.action';

const SUPER_ADMIN_NAV_LINKS = [
    {
        label: 'सेटअप',
        icon: IconSettings,
        links: [
            { label: 'नयाँ नगरपालिका थप्नुहोस्', link: '/municipality' },
            { label: 'पासवर्ड बदल्नुहोस्', link: '/setting' },
        ],
    },
];
const ADMIN_NAV_LINKS = [
    {
        label: 'सेटअप',
        icon: IconSettings,
        links: [
            { label: 'कर्मचारी', link: '/employee' },
            { label: 'पद', link: '/designation' },
            { label: 'पासवर्ड बदल्नुहोस्', link: '/setting' },
        ],
    },
    {
        label: 'कार्ड विवरण',
        icon: IconNote,
        initiallyOpened: true,
        links: [
            { label: 'जेष्ठ नागरिक', link: '/senior-citizen' },
            { label: 'अपाङ्गता', link: '/disability-info' },
            // { label: 'विधुवा', link: '/widow' },
            // { label: 'बाल पोषण', link: '/child-nutrition' },
        ],
    },
];
const STAFF_NAV_LINKS = [
    {
        label: 'सेटअप',
        icon: IconSettings,
        links: [{ label: 'पासवर्ड बदल्नुहोस्', link: '/setting' }],
    },
    {
        label: 'कार्ड विवरण',
        icon: IconNote,
        initiallyOpened: true,
        links: [
            { label: 'जेष्ठ नागरिक', link: '/senior-citizen' },
            { label: 'अपाङ्गता', link: '/disability-info' },
            // { label: 'विधुवा', link: '/widow' },
            // { label: 'बाल पोषण', link: '/child-nutrition' },
        ],
    },
];

export const SideNavBar = () => {
    const { classes } = useStyles();
    const data = useSelector((state: any) => state.loggedUserReducer);
    const dispatch: any = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        successToast('Successfully logout');
    };

    const links =
        data?.user['user']?.role == 1
            ? SUPER_ADMIN_NAV_LINKS.map((item) => <LinksGroup {...item} key={item.label} />)
            : data?.user['user']?.role == 2
            ? ADMIN_NAV_LINKS.map((item) => <LinksGroup {...item} key={item.label} />)
            : STAFF_NAV_LINKS.map((item) => <LinksGroup {...item} key={item.label} />);
    return (
        <Navbar
            width={{ sm: 250, md: 300 }}
            p="md"
            className={'w-72 md:w-72 navbar h-screen  bg-primary-700 text-white mt-[-90px]'}
        >
            <Navbar.Section className={'header px-md border-bottom'}>
                <Group position="center" className={'my-0'}>
                    <Logo />
                </Group>
            </Navbar.Section>

            <Navbar.Section grow className={'links mx-sm'} component={ScrollArea}>
                <Link to={'/'}>
                    <div className={'linksInner mt-md text-white'}>
                        <UnstyledButton className={`${classes.control} mb-[0px]`}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ThemeIcon variant="light" size={30}>
                                    <IconGauge size={18} />
                                </ThemeIcon>
                                <Box ml="md" className={'py-xs'}>
                                    Dashboard
                                </Box>
                            </Box>
                        </UnstyledButton>
                    </div>
                </Link>

                <div className={'linksInner pb-md text-white'}>{links}</div>
            </Navbar.Section>

            <Navbar.Section className={'footer mx-md border-t-2 border-gray-300 flex items-center justify-around py-xs'}>
                <div className={'truncate mr-xs'}>
                    {typeof data?.user['user']?.name != 'object' ? data?.user['user']?.name : 'user'}
                </div>
                <Button type="submit" className="bg-gray-500 mt-xxl hover:bg-sky-700 " onClick={handleLogout}>
                    Logout
                </Button>
            </Navbar.Section>
        </Navbar>
    );
};
